import {
  SupplierDto,
  SupplierEmployeeDto,
  SupplierEmployeeDtoStateEnum,
  VisitationInvitationResponse,
  VisitationInvitationResponseTypeEnum,
  VisitationPinDto,
} from '@qcs/safety-client';
import { Form, FormikHelpers } from 'formik';
import { FormikDebounce } from '../common/form/FormikDebounce';
import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { useAppSnackbar } from '../../hooks/useAppSnackbar';
import { useAppDispatch, useAppSelector } from '../../store';
import { selectIdentityCompanyId } from '../../store/entities/identity';
import { supplierEmployeeApi, visitationApi } from '../../utils/api';
import { validations } from '../../utils/validations';
import { SaveError } from '../common/SaveError';
import { FormContainer } from '../common/form/FormContainer';
import { Input } from '../common/form/Input';
import { SubmitButton } from '../common/form/SubmitButton';
import { SectionHeader } from '../common/SectionHeader';
import { getCustomError, setErrorStateSnacks } from '../../utils/error';
import { visitationListActions } from '../../store/entities/visitationList';
import { RadioGroup } from '../common/form/RadioGroup';
import { QcsFormControlLabel } from '../common/basic/QcsFormControlLabel';
import { Radio } from '@mui/material';
import { SupplierAutocomplete } from '../common/form/SupplierAutocomplete';
import { SupplierEmployeeAutocomplete } from '../common/form/SupplierEmployeeAutocomplete';
import { QcsMenuItem } from '../common/basic/QcsMenuItem';
import { Select, SelectItem } from '../common/form/Select';
import { FaIcon } from '../common/FaIcon';
import {
  faDoorOpen,
  faPersonWalkingArrowRight,
} from '@fortawesome/free-solid-svg-icons';
import { getLangNameObj } from '../../utils/format';
import { ErrorStateType } from '../../models/common';
import { invitationTypeVisitListActions } from '../../store/entities/invitationTypeVisitList';
import { QcsButton } from '@s4e/design-system/molecules/buttons/QcsButton';
import { EstablishmentBasic } from '../common/form/Establishment';

interface VisitationAddPinForm {
  type: 'pin' | 'person';
  invitationId: string;
  pin: string;
  supplier?: SupplierDto;
  supplierEmployee?: SupplierEmployeeDto;
  establishment?: string;
  note?: string;
}

interface Props {
  open: boolean;
  onClose: () => void;
  onOneTimeClick: () => void;
}

export const VisitationAddModalPin: FC<Props> = ({
  open,
  onClose,
  onOneTimeClick,
}) => {
  const { t, i18n } = useTranslation();
  const { enqueueSuccessSnackbar, enqueueErrorSnackbar } = useAppSnackbar();
  const [saveError, setSaveError] = useState<ErrorStateType>();
  const [invitationList, setInvitationList] = useState<
    VisitationInvitationResponse[]
  >([]);
  const identityCompanyId = useAppSelector(selectIdentityCompanyId);
  const dispatch = useAppDispatch();

  useEffect(() => {
    setSaveError('');
    setInvitationList([]);
  }, [open]);

  const getOneOrExitInvitation = (list: VisitationInvitationResponse[]) => {
    if (list.length === 1) {
      return list[0];
    }

    return list.find(
      (x) => x.type === VisitationInvitationResponseTypeEnum.Exit
    );
  };

  const handleSubmit = async (
    data: VisitationAddPinForm,
    formikHelpers: FormikHelpers<VisitationAddPinForm>
  ) => {
    setSaveError('');
    try {
      //Multiple active visitation - second Form.
      if (invitationList.length !== 0) {
        const data2: VisitationPinDto = {
          invitation: data.invitationId,
          pin: data.pin,
          entryEstablishment: data.establishment,
          note: data.note,
        };
        await visitationApi.visit(identityCompanyId!, data2);

        dispatch(visitationListActions.reload(true));
        dispatch(invitationTypeVisitListActions.reload(true));
        enqueueSuccessSnackbar(t('common.saveSuccess'));
        onClose();
        return;
      }

      let pin = data.pin;
      if (data.type === 'person') {
        const revealPinRes = await supplierEmployeeApi.revealPin(
          data.supplierEmployee!.id!
        );
        pin = revealPinRes.data.pin!;
      }

      const data2: VisitationPinDto = {
        invitation: '',
        pin,
        entryEstablishment: data.establishment,
        note: data.note,
      };
      const res = await visitationApi.readPin(identityCompanyId!, data2);

      //No active invitations.
      if ((res.data.data?.length ?? 0) === 0) {
        throw getCustomError('noInvitations');
      }

      //Only one active or exit invitation.
      const oneOrExitInvitation = getOneOrExitInvitation(res.data.data!);
      if (oneOrExitInvitation) {
        const data2: VisitationPinDto = {
          invitation: oneOrExitInvitation.invitation?.id ?? '',
          pin,
          entryEstablishment: data.establishment,
          note: data.note,
        };

        await visitationApi.visit(identityCompanyId!, data2);
        dispatch(visitationListActions.reload(true));
        dispatch(invitationTypeVisitListActions.reload(true));
        enqueueSuccessSnackbar(t('common.saveSuccess'));
        onClose();
        return;
      }

      //Multiple active invitations.
      setInvitationList(res.data.data!);
      formikHelpers.setFieldValue(
        'invitationId',
        res.data.data![0].invitation?.id
      );

      if (data.type === 'person') {
        formikHelpers.setFieldValue('pin', pin);
      }
    } catch (err) {
      setErrorStateSnacks(
        err,
        setSaveError,
        enqueueErrorSnackbar,
        'common.saveError'
      );
    }
  };

  return (
    <>
      <SectionHeader title="visitation.add" />
      <FormikDebounce<VisitationAddPinForm>
        initialValues={{
          type: 'pin',
          invitationId: '',
          pin: '',
          supplier: undefined,
          supplierEmployee: undefined,
          establishment: undefined,
        }}
        validationSchema={Yup.object({
          pin: Yup.string().when('type', {
            is: (type: string) => type === 'pin',
            then: () => validations.stringRequired(t),
          }),
          establishment: Yup.string().required(),
          supplier: Yup.object()
            .nullable()
            .when('type', {
              is: (type: string) => type === 'person',
              then: () => validations.objectRequired(t),
            }),
          supplierEmployee: Yup.object()
            .nullable()
            .when('type', {
              is: (type: string) => type === 'person',
              then: () => validations.objectRequired(t),
            }),
          invitationId:
            invitationList.length === 0
              ? Yup.string()
              : validations.stringRequired(t),
        })}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <FormContainer>
              {invitationList.length === 0 ? (
                <RadioGroup name="type">
                  <FormContainer css={{ marginBottom: '1rem' }}>
                    <QcsFormControlLabel
                      value="pin"
                      control={<Radio />}
                      label={
                        <Input
                          name="pin"
                          label={t('visitation.pin')}
                          required={values.type === 'pin'}
                          disabled={values.type !== 'pin'}
                          fullWidth
                          autoFocus
                        />
                      }
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'min-content auto',
                        marginRight: 0,
                      }}
                    />

                    <QcsFormControlLabel
                      value="person"
                      control={<Radio />}
                      label={
                        <FormContainer>
                          <SupplierAutocomplete
                            name="supplier"
                            label={t('visitation.supplier')}
                            required={values.type === 'person'}
                            disabled={values.type !== 'person'}
                            allowVisit={true}
                          />
                          <SupplierEmployeeAutocomplete
                            name="supplierEmployee"
                            label={t('visitation.supplierEmployee')}
                            required={values.type === 'person'}
                            disabled={
                              values.type !== 'person' || !values.supplier?.id
                            }
                            supplierId={values.supplier?.id}
                            state={[
                              SupplierEmployeeDtoStateEnum.Active,
                              SupplierEmployeeDtoStateEnum.Invited,
                            ]}
                          />
                        </FormContainer>
                      }
                      sx={{
                        display: 'grid',
                        gridTemplateColumns: 'min-content auto',
                        marginRight: 0,
                      }}
                    />
                  </FormContainer>
                  <EstablishmentBasic
                    name="establishment"
                    label={t('workplace.establishment')}
                    required
                    mineOnly
                  />
                  <Input
                    css={{ marginTop: '1rem' }}
                    name="note"
                    label={t('common.notes')}
                  />
                </RadioGroup>
              ) : (
                <Select
                  name="invitationId"
                  label={t('tourniquet.invitation')}
                  required={true}
                  autoFocus={true}
                >
                  {invitationList.map((x) => (
                    <QcsMenuItem
                      key={x.invitation?.id}
                      value={x.invitation?.id}
                    >
                      <SelectItem>
                        <FaIcon
                          icon={
                            x.type === VisitationInvitationResponseTypeEnum.Exit
                              ? faDoorOpen
                              : faPersonWalkingArrowRight
                          }
                        />
                        <div>{getLangNameObj(i18n, x.invitation)}</div>
                      </SelectItem>
                    </QcsMenuItem>
                  ))}
                </Select>
              )}

              <SaveError error={saveError} />
              <SubmitButton />
            </FormContainer>
          </Form>
        )}
      </FormikDebounce>

      {invitationList.length === 0 && (
        <QcsButton
          variant="contained"
          fullWidth={true}
          sx={{ mt: '1rem' }}
          onClick={onOneTimeClick}
        >
          {t('visitation.oneTime')}
        </QcsButton>
      )}
    </>
  );
};
